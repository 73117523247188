.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  margin: auto;
}

.button {
  margin: auto;
  width: 100%;
  height: 60px;
  font-size: 24px;
}

.resumeImage {
  width: 100%;
  margin: auto;
}
